:root{
  --spin-angle: 3600deg;
}

button {
  padding: 1em 2em;
  margin: 1em 0.5em;
  border: 0;
  border-radius: 3px;
  font-family: Helvetica, Arial;
}

.buttons{
  padding: 4em 0em 0em 0em;
}

.coin-widget{
  position: relative;
  top: 15em;
  text-align: center;
  margin: auto;
}

.coin {
  position: relative;
  height: 75px;
  width: 75px;
  margin: auto;
}

.coin-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.coin-heads {
  position: relative;
  width: 100%;
  height: 100%;
  animation-iteration-count: 1;
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transform-style: preserve-3d;
}

.coin-tails {
  position: relative;
  width: 100%;
  height: 100%;
  animation-iteration-count: 1;
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transform-style: preserve-3d;
  --spin-angle: 3420deg;
}

.coin-hidden {
  display: none;
}

.front, .back{
  position: absolute;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
  z-index: -1;
}
@keyframes spin {

  0% {
    transform: rotateY(0deg) translateY(-5em);
   }

  80% {
    transform: rotateY(calc(var(--spin-angle) - 500deg)) translateY(-5em);
   }
  100% {
    transform: rotateY(var(--spin-angle)) translateY(0em);
  }
}
